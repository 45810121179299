// src/components/ChatWidget.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './ChatWidget.css'; // Import CSS for styling

const ChatWidget = () => {
  const whatsappNumber = '+27679964995'; // Replace with your WhatsApp number

  return (
    <div className="chat-widget">

      <a
        href={`https://wa.me/${whatsappNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="chat-link"
      >

        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </div>
  );
};

export default ChatWidget;
