import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import logo from './logo.png';  // Assuming logo.png is your logo with no background
import backgroundVideo from './background.mp4';  // Assuming background.mp4 is your video file
import ChatWidget from './ChatWidget'; // Import the ChatWidget component
import soilTestingImg from './assets/images/soil-testing.jpg';
import dampProofingImg from './assets/images/damp-proofing.jpg';
import basementsImg from './assets/images/basements.jpg';
import waterReservoirsImg from './assets/images/water-reservoirs.jpg';
import parapetsImg from './assets/images/parapets.jpg';
import ibrRoofsImg from './assets/images/ibr-roofs.jpg';
import paintingImg from './assets/images/painting.jpg';

function App() {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => setNavOpen(!isNavOpen);

  return (
    <div className="app">
      <Helmet>
        <title>Topcore</title>
        <meta name="description" content="Topcore - Your go-to provider for quality solutions in Polokwane. Contact us at +27 67 996 4995." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Topcore",
              "url": "https://topcore.co.za",
              "telephone": "+27 67 996 4995",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Polokwane Dorp 94",
                "addressLocality": "Polokwane",
                "addressCountry": "ZA"
              },
              "sameAs": [
                "https://www.facebook.com/topcore",
                "https://www.linkedin.com/company/topcore"
              ],
              "logo": "https://topcore.co.za/logo.png"
            }
          `}
        </script>
      </Helmet>   
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-image" />
          <span className="sr-only">Top Core Waterproofing</span>
        </div>
        <button className="hamburger" onClick={toggleNav}>
          <span className="hamburger-icon"></span>
        </button>
        <nav className={`nav ${isNavOpen ? 'nav-open' : ''}`}>
          <a href="#" className="nav-link">Services</a>
          <a href="#" className="nav-link">About</a>
          <a href="#" className="nav-link">Testimonials</a>
          <a href="#" className="nav-link">Contact</a>
        </nav>
      </header>
      <main className="main-content">
        <section className="hero-section">
          <video autoPlay muted loop className="background-video">
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay">
            <div className="hero-text">
              <h1 className="hero-title">Quality Proofing solutions.</h1>
              <p className="hero-description">
                We know what is best for you.
              </p>
              <a href="#" className="hero-button">Get a Free Quote</a>
            </div>
          </div>
        </section>
        <section className="services-section">
          <div className="container">
            <h2 className="section-title">Our Services</h2>
            <p className="section-description">
              We offer a variety of specialized services to meet your needs.
            </p>
            <div className="services-grid">
              <div className="service-card">
                <img src={soilTestingImg} alt="Soil Testing" className="service-icon" />
                <h3 className="service-title">Soil Testing</h3>
                <p className="service-description">
                  Ensure the quality and suitability of soil for construction with our thorough testing services.
                </p>
              </div>
              <div className="service-card">
                <img src={dampProofingImg} alt="Damp Proofing" className="service-icon" />
                <h3 className="service-title">Damp Proofing</h3>
                <p className="service-description">
                  Keep your property secure with our expert damp proofing services. We prevent moisture damage and ensure lasting protection.
                </p>
              </div>
              <div className="service-card">
                <img src={basementsImg} alt="Basements" className="service-icon" />
                <h3 className="service-title">Basements</h3>
                <p className="service-description">
                  Keep your basements dry and secure with our specialized basement services.
                </p>
              </div>
              <div className="service-card">
                <img src={waterReservoirsImg} alt="Water Reservoirs" className="service-icon" />
                <h3 className="service-title">Water Reservoirs</h3>
                <p className="service-description">
                  Ensure your water reservoirs are well-maintained and protected.
                </p>
              </div>
              <div className="service-card">
                <img src={parapetsImg} alt="Parapets" className="service-icon" />
                <h3 className="service-title">Parapets</h3>
                <p className="service-description">
                  Enhance the durability and appearance of your parapets with our expert services.
                </p>
              </div>
              <div className="service-card">
                <img src={ibrRoofsImg} alt="IBR Roofs" className="service-icon" />
                <h3 className="service-title">IBR Roofs</h3>
                <p className="service-description">
                  Professional solutions for maintaining and repairing IBR roofs.
                </p>
              </div>
              <div className="service-card">
                <img src={paintingImg} alt="Painting" className="service-icon" />
                <h3 className="service-title">Painting</h3>
                <p className="service-description">
                  Transform and protect your property with our expert painting services.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonials-section">
          <div className="container">
            <h2 className="section-title">What Our Customers Say</h2>
            <p className="section-description">
              Hear from our satisfied customers about their experience with our waterproofing services.
            </p>
            <div className="testimonials-grid">
              <div className="testimonial-card">
                <img src="/placeholder.svg" alt="John Doe" className="testimonial-avatar" />
                <div className="testimonial-content">
                  <h4 className="testimonial-name">John Doe</h4>
                  <p className="testimonial-role">Homeowner</p>
                  <p className="testimonial-text">
                    "The team at Top Core did an amazing job waterproofing my basement. They were professional, efficient,
                    and the results have been fantastic. I highly recommend their services."
                  </p>
                </div>
              </div>
              <div className="testimonial-card">
                <img src="/placeholder.svg" alt="Jane Smith" className="testimonial-avatar" />
                <div className="testimonial-content">
                  <h4 className="testimonial-name">Jane Smith</h4>
                  <p className="testimonial-role">Homeowner</p>
                  <p className="testimonial-text">
                    "I was really impressed with the level of expertise and attention to detail from the Top Core team.
                    They took the time to understand my needs and provided a comprehensive solution that has kept my home
                    dry ever since."
                  </p>
                </div>
              </div>
              <div className="testimonial-card">
                <img src="/placeholder.svg" alt="Michael Johnson" className="testimonial-avatar" />
                <div className="testimonial-content">
                  <h4 className="testimonial-name">Michael Johnson</h4>
                  <p className="testimonial-role">Homeowner</p>
                  <p className="testimonial-text">
                    "Top Core exceeded my expectations. They were professional, courteous, and the work they did has
                    transformed my home. I no longer have to worry about water damage, and I'm grateful for their expertise."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="container">
            <h2 className="section-title">Get a Free Quote</h2>
            <p className="section-description">
              Fill out the form below to schedule a free consultation and get a quote for your waterproofing project.
            </p>
            <form className="contact-form">
              <input type="text" placeholder="Name" className="contact-input" />
              <input type="email" placeholder="Email" className="contact-input" />
              <textarea placeholder="Message" className="contact-textarea"></textarea>
              <button type="submit" className="contact-button">Get a Quote</button>
            </form>
          </div>
        </section>
      </main>
      <footer className="footer">
        <p className="footer-text">&copy; 2024 Top Core Waterproofing. All rights reserved.</p>
        <nav className="footer-nav">
          <a href="#" className="footer-link">Terms of Service</a>
          <a href="#" className="footer-link">Privacy Policy</a>
        </nav>
      </footer>
      <ChatWidget /> {/* Add ChatWidget component here */}
    </div>
  );
}

export default App;
